import AurelienGeorget from 'img/landing/aurelien.webp';
import ChelseaDarby from 'img/landing/chelsea-darby.webp';
import FelixHiggs from 'img/landing/felix-small.webp';
import IdaStrom from 'img/landing/ida-strom.webp';
import JayPatel from 'img/landing/jay-patel.webp';
import JesseSandala from 'img/landing/jesse.webp';
import KeenanJones from 'img/landing/keenan-jones.webp';
import LiyaAi from 'img/landing/lia-small.webp';
import MarcusMoretti from 'img/landing/marcus.webp';
import MattCromwell from 'img/landing/matt-cromwell.webp';
import MichaelaRollings from 'img/landing/michaela-rollings.webp';
import NickBarraclough from 'img/landing/nick-barraclough.webp';
import OwenDoherty from 'img/landing/owen-doherty.webp';
import PhilippeLehoux from 'img/landing/philippe.webp';
import RachelSchaaf from 'img/landing/rachel-schaaf.webp';
import SaravanaKumar from 'img/landing/saravana-small.webp';
import TimBennetto from 'img/landing/tim-bennetto-headshot.webp';
import ZebEvans from 'img/landing/zeb-evans.webp';

const Headshots = {
  AurelienGeorget,
  ChelseaDarby,
  FelixHiggs,
  IdaStrom,
  JayPatel,
  JesseSandala,
  KeenanJones,
  LiyaAi,
  MarcusMoretti,
  MattCromwell,
  MichaelaRollings,
  NickBarraclough,
  OwenDoherty,
  PhilippeLehoux,
  RachelSchaaf,
  SaravanaKumar,
  TimBennetto,
  ZebEvans,
};

export default Headshots;
