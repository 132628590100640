import React from 'react';

import Helmet from 'common/helmets/Helmet';
import LandingFAQEntry, {
  type AnswerLine,
  type LandingFAQEntryProps,
} from 'common/www/LandingV3/LandingFAQ/LandingFAQEntry';
import LandingHeading from 'common/www/LandingV3/LandingHeading';
import styles from 'css-module/components/www/_LandingFAQ.module.scss';

/**
 * Convert lines to a single string
 * @param lines a string, array of strings, or an object with `text` as a key
 * @returns lines concatenated as one string
 */
const answerLinesToString = (lines: AnswerLine[]) => {
  return lines
    .map((line) => {
      if (typeof line === 'string') {
        return line;
      } else if (Array.isArray(line)) {
        return line.join(', ') + '. ';
      } else {
        return line.text;
      }
    })
    .join(' ');
};

type Copy = {
  title: string;
  subtitle: string;
};

export type LandingFAQProps = {
  copy?: Copy;
  faqs: LandingFAQEntryProps[];
};

const index = ({
  copy = {
    title: 'Frequently asked questions',
    subtitle: "You've got questions. We've got answers.",
  },
  faqs,
}: LandingFAQProps) => {
  // following: https://developers.google.com/search/docs/appearance/structured-data/faqpage
  const structuredFAQJSON = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    name: 'Frequently Asked Questions',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answerLinesToString(faq.answer),
      },
    })),
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{structuredFAQJSON}</script>
      </Helmet>
      <section className={styles.LandingFAQ}>
        <LandingHeading className={styles.heading} center {...copy} />
        <ul className={styles.list}>
          {faqs.map((faq, index) => (
            <LandingFAQEntry {...faq} key={`landing-faq-${index}`} />
          ))}
        </ul>
      </section>
    </>
  );
};

export default index;
