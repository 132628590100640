import analyze from 'img/landing/analyze.webp';
import autopilot1_full from 'img/landing/autopilot-1-full.webp';
import autopilot1 from 'img/landing/autopilot-1.webp';
import autopilot2_full from 'img/landing/autopilot-2-full.webp';
import autopilot2 from 'img/landing/autopilot-2.webp';
import autopilot3_full from 'img/landing/autopilot-3-full.webp';
import autopilot3 from 'img/landing/autopilot-3.webp';
import block1_0p75x from 'img/landing/block-1-0.75x.webp';
import block1_1x from 'img/landing/block-1-1x.webp';
import block1_2x from 'img/landing/block-1-2x.webp';
import block2_0p75x from 'img/landing/block-2-0.75x.webp';
import block2_1x from 'img/landing/block-2-1x.webp';
import block2_2x from 'img/landing/block-2-2x.webp';
import block3_0p75x from 'img/landing/block-3-0.75x.webp';
import block3_1x from 'img/landing/block-3-1x.webp';
import block3_2x from 'img/landing/block-3-2x.webp';
import gradient1 from 'img/landing/bug-tracking-gradient.webp';
import bugs_notify from 'img/landing/bugs-notify.webp';
import bugs_roadmap from 'img/landing/bugs-roadmap.webp';
import capture_bugs from 'img/landing/capture-bugs.webp';
import changelog from 'img/landing/changelog.webp';
import collectNoPadding from 'img/landing/collect-no-padding.webp';
import collect from 'img/landing/collect.webp';
import hero_0p5x from 'img/landing/hero-0.5x.webp';
import hero_1p5x from 'img/landing/hero-1.5x.webp';
import hero_1x from 'img/landing/hero-1x.webp';
import internal_bugs from 'img/landing/internal-bugs.webp';
import prioritize from 'img/landing/prioritize.webp';
import roadmaps from 'img/landing/roadmaps.webp';
import security from 'img/landing/security.webp';
import setup_billing from 'img/landing/setup-billing.webp';
import support from 'img/landing/support.webp';

const LandingImages = {
  roadmaps,
  prioritize,
  hero: {
    '0.5x': hero_0p5x,
    '1x': hero_1x,
    '1.5x': hero_1p5x,
  },
  collect,
  collectNoPadding,
  changelog,
  block3: {
    '0.75x': block3_0p75x,
    '1x': block3_1x,
    '2x': block3_2x,
  },
  block2: {
    '0.75x': block2_0p75x,
    '1x': block2_1x,
    '2x': block2_2x,
  },
  block1: {
    '0.75x': block1_0p75x,
    '1x': block1_1x,
    '2x': block1_2x,
  },
  gradient1,
  autopilot1,
  autopilot2,
  autopilot3,
  autopilot1_full,
  autopilot2_full,
  autopilot3_full,
  analyze,
  security,
  support,
  setup_billing,
  internal_bugs,
  capture_bugs,
  bugs_roadmap,
  bugs_notify,
};
export default LandingImages;
