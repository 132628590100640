import AsanaLogo from 'img/landing/asana-logo.webp';
import Azure from 'img/landing/azure-logo.webp';
import ColorfulClickupLogo from 'img/landing/clickup-logo.webp';
import CreditRepairCloud from 'img/landing/creditrepaircloud-logo.webp';
import HubspotLogo from 'img/landing/hubspot-logo.webp';
import Okta from 'img/landing/okta-logo.webp';
import OrcaScan from 'img/landing/orcascan-logo.webp';
import Pallyy from 'img/landing/pallyy-wordmark-black.webp';
import StellarWP from 'img/landing/stellarwp-logo.webp';

import AgencyAnalytics from 'img/landing/agency-analytics-logo.svg';
import Ahrefs from 'img/landing/ahrefs-logo.svg';
import Aryeo from 'img/landing/aryeo-logo.svg';
import Axios from 'img/landing/axios-logo.svg';
import CircleCI from 'img/landing/circleci-logo.svg';
import ClickUp from 'img/landing/clickup-logo.svg';
import Document360 from 'img/landing/document360-logo.svg';
import Figured from 'img/landing/figured-logo.svg';
import Flock from 'img/landing/flock-logo.svg';
import GitHub from 'img/landing/github-logo.svg';
import GiveButter from 'img/landing/givebutter-logo.svg';
import Hive from 'img/landing/hive-logo.svg';
import Intercom from 'img/landing/intercom-logo.svg';
import Jane from 'img/landing/jane-logo.svg';
import Jira from 'img/landing/jira-logo.svg';
import Knak from 'img/landing/knak-logo.svg';
import Loom from 'img/landing/loom-logo.svg';
import Mercury from 'img/landing/mercury-logo.svg';
import Missive from 'img/landing/missive-logo.svg';
import Salesforce from 'img/landing/salesforce-logo.svg';
import Slack from 'img/landing/slack-logo.svg';
import Strapi from 'img/landing/strapi-logo.svg';
import Zapier from 'img/landing/zapier-logo.svg';
import Zendesk from 'img/landing/zendesk-logo.svg';

export const SVGLogos = {
  AgencyAnalytics,
  Ahrefs,
  Aryeo,
  Axios,
  CircleCI,
  ClickUp,
  Document360,
  Figured,
  Flock,
  GitHub,
  GiveButter,
  Hive,
  Intercom,
  Jane,
  Jira,
  Knak,
  Loom,
  Mercury,
  Missive,
  Salesforce,
  Slack,
  Strapi,
  Zapier,
  Zendesk,
};

export const WEBPLogos = {
  AsanaLogo,
  Azure,
  ColorfulClickupLogo,
  CreditRepairCloud,
  HubspotLogo,
  Okta,
  OrcaScan,
  Pallyy,
  StellarWP,
};
