import React from 'react';

import Helmet from 'common/helmets/Helmet';

const DefaultShareImage = 'https://canny.io/images/canny-social.png';
const XHandle = '@cannyHQ';

export type Props = {
  title: string;
  description: string;
  pageURL: string;
  imageURL?: string;
};

const GenericLandingHelmet = ({
  title,
  description,
  pageURL,
  imageURL = DefaultShareImage,
}: Props) => {
  const titleWithPipe = `${title} | Canny`;

  return (
    <Helmet
      title={titleWithPipe}
      meta={[
        { name: 'description', content: description },

        { property: 'og:image', content: imageURL },
        { property: 'og:title', content: titleWithPipe },
        { property: 'og:description', content: description },
        { property: 'og:url', content: pageURL },
        { property: 'og:type', content: 'website' },

        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: XHandle },
        { name: 'twitter:title', content: titleWithPipe },
        { name: 'twitter:description', content: description },
        { name: 'twitter:image', content: imageURL },
      ]}
    />
  );
};

export default GenericLandingHelmet;
