import React from 'react';

import GenericLandingHelmet, { type Props as Helmet } from 'common/helmets/GenericLandingHelmet';
import LandingImages from 'common/www/assets/LandingImages';
import LandingFeaturePageLinks from 'common/www/LandingV3/common/LandingFeaturePageLinks';
import IntegrationData from 'common/www/LandingV3/Data/IntegrationData';
import TestimonialData from 'common/www/LandingV3/Data/TestimonialData';
import LandingContentBlock, { type Props as Block } from 'common/www/LandingV3/LandingContentBlock';
import LandingCTA, { type Props as CTA } from 'common/www/LandingV3/LandingCTA';
import LandingFAQ, { type LandingFAQProps } from 'common/www/LandingV3/LandingFAQ';
import LandingHero, { type Props as Hero } from 'common/www/LandingV3/LandingHero/';
import LandingIntegrations, {
  type Props as Integrations,
} from 'common/www/LandingV3/LandingIntegrations';
import LandingTestimonials, {
  type Props as Testimonials,
} from 'common/www/LandingV3/LandingTestimonials';

import 'css/components/www/LandingV3/_LandingEnterpriseFeedbackManagement.scss';

const HelmetData: Helmet = {
  title: 'Enterprise feedback management software',
  description:
    'Discover a feedback solution built to support the unique needs of large businesses. Featuring advanced security, flexible account management, and powerful AI.',
  pageURL: 'https://canny.io/features/enterprise-feedback-management',
};

const HeroSection: Hero = {
  copy: {
    title: 'Enterprise feedback management made easy',
    subtitle:
      'Enjoy industry-leading features, reliability, security, and support. Uncover customer insights at any scale.',
    registerButton: 'Get started for free',
    cost: 'No credit card required',
  },
  pretitle: <LandingHero.TextPretitle>Enterprise feedback management</LandingHero.TextPretitle>,
};

const EFMTestimonials: Testimonials = {
  copy: {
    title: 'Companies like yours count on Canny',
    subtitle:
      "Don't take our word for it. See how our enterprise feedback management software helps teams save time and make better decisions.",
  },
  categorizedTestimonials: {
    general: [
      TestimonialData.ClickUp(
        "Getting Canny set up couldn't have been easier. We started with a blank canvas and allowed our users to paint the rest."
      ),
      TestimonialData.Mercury(
        "We use Canny as a way to distribute product thinking across the team. Now that everything is in one place, it's so much easier and more efficient."
      ),
      TestimonialData.Axios(
        'Canny made the most important and urgent user feedback stand out, so we could innovate our product and deliver an even better experience.'
      ),
    ],
  },
};

const EFMContentBlocks: Block[] = [
  {
    copy: {
      title: 'Organize all your feedback in one place',
      subtitle:
        'Capture feedback data from all sources, deduplicate it, and securely store it. Create boards for customer or employee feedback and keep everyone updated.',
    },
    image: {
      src: LandingImages.collectNoPadding,
      alt: 'Many industry standard services can feed into Canny.',
    },
  },
  {
    copy: {
      title: 'Let Autopilot do the heavy lifting',
      subtitle:
        "Autopilot's AI automatically collects feedback from your customer communications. Instantly summarize lengthy discussions and auto-respond to user feedback.",
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: 'Posts that are detected as duplicates will be merged together, and marked accordingly.',
    },
  },
  {
    copy: {
      title: 'Uncover revenue-driving features',
      subtitle:
        "Connect HubSpot or Salesforce to segment customers, prioritize features by impact, and reduce churn. Focus on key customers' feedback to drive revenue.",
    },
    image: {
      src: LandingImages.prioritize,
      alt: 'Use inputs such as impact and priority to score your tasks.',
    },
  },
  {
    copy: {
      title: 'Deliver an amazing customer experience',
      subtitle:
        "Collect all your customers' feedback, keep them updated on feature requests, and discuss their issues. Boost customer satisfaction by acting on feedback.",
    },
    image: {
      src: LandingImages.autopilot2_full,
      alt: 'A user requests a feature and an automatic reply is sent asking for more details.',
    },
  },
  {
    copy: {
      title: 'Security you can count on',
      subtitle:
        "Enjoy industry-leading security and compliance you won't find with smaller vendors— SOC II Type 2, GDPR, CCPA. Use single sign-on via Azure, Okta, and more.",
    },
    image: {
      alt: 'GDPR, AICPA SOC, and CCPA badges.',
      src: LandingImages.security,
    },
  },
  {
    copy: {
      title: 'Award-winning customer support',
      subtitle:
        'Our dedicated success team ensures you get the most out of Canny—personalized onboarding, regular check-ins, and a private Slack channel for your team.',
    },
    image: {
      src: LandingImages.support,
      alt: 'Our dedicated success team checking in to make sure your onboarding is going smoothly.',
    },
  },
  {
    copy: {
      title: 'Flexible account setup and billing',
      subtitle:
        "Our Business plan supports large companies' needs with custom roles, unlimited seats, user control, SSO integrations, and invoice billing.",
    },
    image: {
      src: LandingImages.setup_billing,
      alt: 'We offer OneLogin, Okta, OpenID Connect, and Azure login options, with customizable role permissions.',
    },
  },
];

const EFMIntegrations: Integrations = {
  copy: {
    title: 'No-headache, seamless integrations',
    subtitle:
      'Get unlimited integrations with your Business plan. Connect HubSpot or SalesForce to tie revenue to feedback. Authenticate users with Okta SSO or Azure for added security.',
    seeAllLink: 'See all integrations',
  },
  integrations: [
    [
      IntegrationData.Salesforce,
      IntegrationData.Hubspot,
      IntegrationData.Azure,
      IntegrationData.Okta,
    ],
  ],
};

const FAQ: LandingFAQProps = {
  faqs: [
    {
      question: 'Is our customer data secure?',
      answer: [
        "Yes, Canny offers industry-leading security and can provide a SOC2 Type 2 report. Most smaller EFM Software providers don't have this level of security and compliance.",
      ],
    },
    {
      question: 'What tools does Canny offer?',
      answer: [
        'Canny offers a full suite of tools designed to help you manage customer feedback. They cover feedback collection, roadmaps, prioritization, and product updates.',
        {
          node: (
            <p>
              We also{' '}
              <a href="https://canny.io/integrations" rel="noopener" target="_blank">
                integrate
              </a>{' '}
              with other best in class tools so you can use the best tools on the market for all
              your needs. That includes project management tools, CRMs, customer support, analytics,
              and more.
            </p>
          ),
          text: 'We also <a href="https://canny.io/integrations" rel="noopener" target="_blank">integrate</a> with other best in class tools so you can use the best tools on the market for all your needs. That includes project management tools, CRMs, customer support, analytics, and more.',
        },
        'Canny can pull customer survey data from your CRM or bulk import it. That includes survey results, net promoter score (NPS) data, customer effort score (CES), etc. You can create custom fields in Canny for these, and use them to analyze feedback.',
      ],
    },
    {
      question: 'What are the benefits of the Hubspot and Salesforce integrations?',
      answer: [
        'These help your product team understand what features they should be building to unlock more revenue for your company. You can connect deals in Hubspot and Salesforce to feedback in Canny. This lets you track the revenue impact of customer feedback.',
        'You can also sync custom fields like ARR that can be used as an impact factor when prioritizing requests.',
      ],
    },
    {
      question: 'Do you offer an API?',
      answer: [
        'Yes, our open API is available to all customers. We also offer integration support to enterprise clients from our support engineering team. They help customize Canny to make sure it supports your workflow.',
      ],
    },
    {
      question: 'How does Canny help with customer experience management?',
      answer: [
        "We believe that you can build a better product by listening to customer feedback. Our tools are designed to help you manage the entire customer feedback loop — listening, analyzing, prioritizing, building, and then announcing. Keeping your customers informed as you act on their feedback boosts customer loyalty and retention. It also helps with customer engagement as you invite them to be a part of your product's development. Feeling heard and involved is a major driver of customer experience. ",
      ],
    },
  ],
};

const cta: CTA = {
  copy: {
    title: "See how Canny's Enterprise plan can support your team",
    subtitle: 'Schedule your demo today, or sign up for a free trial to explore for yourself.',
    demoButton: 'Request a demo',
    registerButton: 'Get started',
  },
};

const LandingEnterpriseFeedbackManagement = () => {
  return (
    <>
      <GenericLandingHelmet {...HelmetData} />
      <main className="LandingEnterpriseFeedbackManagement" id="main">
        <LandingHero {...HeroSection} />
        <LandingFeaturePageLinks
          title="Your complete enterprise feedback management system"
          subtitle="Listen to your customers and action their feedback"
        />
        {EFMContentBlocks.map((block, index) => {
          return (
            <LandingContentBlock
              flip={index % 2 === 1}
              {...block}
              key={`Autopilot__content-block__${index}`}
            />
          );
        })}
        <LandingIntegrations {...EFMIntegrations} />
        <LandingTestimonials centerHeading {...EFMTestimonials} />
        <LandingFAQ {...FAQ} />
        <LandingCTA
          style={{
            backgroundImage: `url(${LandingImages.gradient1})`,
          }}
          orientation="vertical"
          className="LandingEnterpriseFeedbackManagement__cta"
          {...cta}
        />
      </main>
    </>
  );
};

export default LandingEnterpriseFeedbackManagement;
