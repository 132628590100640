import React, { useState } from 'react';

import LandingHeading from 'common/www/LandingV3//LandingHeading';
import LandingTabBody from 'common/www/LandingV3/LandingTabs//LandingTabBody';
import LandingTabCards from 'common/www/LandingV3/LandingTabs//LandingTabCards';
import styles from 'css-module/components/www/LandingTabs/_LandingTabs.module.scss';

import type { TabWithBody as Tab } from 'common/www/LandingV3/LandingTabs';

type Copy = {
  title: string;
  subtitle: string;
};

export type Props = {
  copy: Copy;
  tabs: Tab[];
  defaultActiveTab?: number;
};

const LandingTabsWithBody = ({ copy, tabs, defaultActiveTab = 0 }: Props) => {
  const [currentTabIndex, setTabIndex] = useState(defaultActiveTab);
  return (
    <section className={styles.container}>
      <LandingHeading {...copy} center className={styles.heading} />
      <LandingTabCards tabs={tabs} currentTabIndex={currentTabIndex} setTabIndex={setTabIndex} />
      <LandingTabBody tabs={tabs} currentTabIndex={currentTabIndex} />
    </section>
  );
};

export default LandingTabsWithBody;
