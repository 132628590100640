import React from 'react';

import classnames from 'classnames';
import { type LucideIcon } from 'lucide-react';

import 'css/components/www/LandingV3/_LandingContentBlock.scss';

import LazyLoadedImage from 'common/LazyLoadedImage';
import nbspLastSpace from 'common/util/nbspLastSpace';
import { type LandingImage } from 'common/www/LandingV3/types';
import { convertToSrcSetString } from 'common/www/LandingV3/utils';

export type Props = {
  copy: {
    title: string;
    subtitle: string;
  };
  points?: { icon: LucideIcon; title: string; body: string }[];
  flip?: boolean;
  image: LandingImage;
};

const LandingContentBlock = ({
  copy: { title, subtitle },
  image,
  points = [],
  flip = false,
}: Props) => {
  return (
    <section className={classnames('LandingContentBlock', { 'LandingContentBlock--flip': flip })}>
      <div className="LandingContentBlock__copy">
        <h2 className="LandingContentBlock__title">{nbspLastSpace(title)}</h2>
        <p className="LandingContentBlock__subtitle">{nbspLastSpace(subtitle)}</p>
        {points.length > 0 ? (
          <ul className="LandingContentBlock__point-container">
            {points.map(({ icon: Icon, title, body }, index) => {
              return (
                <li className="LandingContentBlock__point-item" key={index}>
                  <Icon aria-hidden className="LandingContentBlock__point-icon" />
                  <p className="LandingContentBlock__point-title">{title}</p>
                  <p className="LandingContentBlock__point-body">{nbspLastSpace(body)}</p>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
      <LazyLoadedImage
        {...image}
        srcSet={convertToSrcSetString(image.srcSet)}
        className="LandingContentBlock__image"
        loading="lazy"
      />
    </section>
  );
};

export default LandingContentBlock;
