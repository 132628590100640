import React from 'react';

import { ArrowRight } from 'lucide-react';

import Colors from 'common/colors/constants';
import Pill from 'common/common/Pill';
import Link from 'common/Link';
import { P } from 'common/ui/Text';
import styles from 'css-module/components/www/_AutopilotCallout.module.scss';

const AutopilotCallout = () => {
  return (
    <Link className={styles.container} to="/features/autopilot">
      <Pill pillStyle={{ background: Colors.indigo40, color: Colors.indigo90 }}>
        <span className={styles.pillCopy}>New</span>
      </Pill>
      <P fontWeight="semibold" className={styles.copy}>
        Capture feedback automatically with AI ✨
      </P>
      <ArrowRight size={16} strokeWidth={2} />
    </Link>
  );
};

export default AutopilotCallout;
