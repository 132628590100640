import React from 'react';

import classnames from 'classnames';

import LazyLoadedImage from 'common/LazyLoadedImage';
import nbspLastSpace from 'common/util/nbspLastSpace';
import { convertToSrcSetString } from 'common/www/LandingV3/utils';
import styles from 'css-module/components/www/LandingTabs/_LandingTabBody.module.scss';

import type { TabWithBody as Tab } from 'common/www/LandingV3/LandingTabs/';

type Props = {
  tabs: Tab[];
  currentTabIndex: number;
};

const LandingTabBody = ({ tabs, currentTabIndex }: Props) => {
  return (
    <div className={styles.container}>
      {tabs.map(({ title, tabTitle = title, image, tabBody, imageStyleAdjustments }, index) => {
        const isShown = index === currentTabIndex;
        return (
          <div
            key={title}
            className={classnames(styles.innerWrapper, {
              [styles.hide]: !isShown,
            })}
            aria-hidden={!isShown}>
            <div className={styles.copyContainer}>
              <h3 className={styles.title}>{tabTitle}</h3>
              <div className={styles.body}>
                {tabBody.map((blurb) => {
                  if (typeof blurb === 'string') {
                    return (
                      <p className={styles.text} key={blurb}>
                        {nbspLastSpace(blurb)}
                      </p>
                    );
                  } else {
                    return (
                      <ul key={blurb[0]} className={styles.list}>
                        {blurb.map((line) => {
                          return (
                            <li key={line}>
                              <p className={styles.text}>{nbspLastSpace(line)}</p>
                            </li>
                          );
                        })}
                      </ul>
                    );
                  }
                })}
              </div>
            </div>
            <LazyLoadedImage
              {...image}
              srcSet={convertToSrcSetString(image.srcSet)}
              className={styles.img}
              loading="lazy"
              style={imageStyleAdjustments}
            />
          </div>
        );
      })}
    </div>
  );
};

export default LandingTabBody;
