import React from 'react';

import 'css/components/www/LandingV3/_LandingStats.scss';

import nbspLastSpace from 'common/util/nbspLastSpace';

type Stat = {
  metric: string;
  subtitle: string;
};

type Copy = {
  title: string;
};

export type Props = {
  copy: Copy;
  stats: Stat[];
};

const LandingStats = ({ copy: { title }, stats }: Props) => {
  return (
    <section className="LandingStats">
      <h2 className="LandingStats__title">{nbspLastSpace(title)}</h2>
      <ul className="LandingStats__stat-container">
        {stats.map(({ metric, subtitle }, index) => {
          return (
            <li className="LandingStats__stat-item" key={index}>
              <p className="LandingStats__stat-copy">
                <span className="LandingStats__stat-metric">{metric}</span>
                <span className="LandingStats__stat-subtitle">{subtitle}</span>
              </p>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default LandingStats;
