import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { ChevronDown } from 'lucide-react';

import nbspLastSpace from 'common/util/nbspLastSpace';
import styles from 'css-module/components/www/_LandingFAQEntry.module.scss';

export type AnswerLine = string | string[] | { text: string; node: React.ReactNode };

export type LandingFAQEntryProps = {
  question: string;
  answer: AnswerLine[];
};

const LandingFAQEntry = ({ question, answer }: LandingFAQEntryProps) => {
  const [open, setOpen] = useState(false);
  const answerWrapper = useRef<HTMLDivElement | null>(null);
  const innerAnswerWrapper = useRef<HTMLDivElement | null>(null);
  const [targetAnswerWrapperHeight, setTargetAnswerWrapperHeight] = useState(0);

  useEffect(() => {
    setTargetAnswerWrapperHeight(innerAnswerWrapper.current?.getBoundingClientRect().height ?? 0);
  }, [innerAnswerWrapper]);

  const onToggleAnswerVisibility = () => {
    setOpen(!open);
  };

  return (
    <li
      className={classnames(styles.LandingFAQEntry, {
        [styles.open]: open,
      })}>
      <div className={styles.question}>
        <button
          className={styles.input}
          aria-label={`The answer to ${question} is currently ${
            open ? 'expanded' : 'contracted'
          }. Click to ${open ? 'hide' : 'show'} answer.`}
          onClick={onToggleAnswerVisibility}>
          {nbspLastSpace(question)}
          <ChevronDown strokeWidth={1.5} className={styles.chevron} />
        </button>
      </div>
      <div
        ref={answerWrapper}
        style={{ height: open ? targetAnswerWrapperHeight : 0 }}
        className={styles.answerWrapper}
        aria-hidden={!open}>
        <div className={styles.answer} ref={innerAnswerWrapper}>
          {answer.map((line, index) => {
            if (typeof line === 'string') {
              return (
                <p key={`landing-faq-answer-line-${index}`} className={styles.answerLine}>
                  {line}
                </p>
              );
            } else if (Array.isArray(line)) {
              return (
                <ul
                  key={`landing-faq-answer-line-${index}`}
                  className={classnames(styles.answerLine, styles.answerList)}>
                  {line.map((point, pointIndex) => {
                    return (
                      <li
                        key={`landing-faq-answer-bullet-point-${pointIndex}`}
                        className={styles.answerListItem}>
                        <p className={styles.answerListItemCopy}>{point}</p>
                      </li>
                    );
                  })}
                </ul>
              );
            } else {
              return (
                <div className={styles.answerLine} key={`landing-faq-answer-line-${index}`}>
                  {line.node}
                </div>
              );
            }
          })}
        </div>
      </div>
    </li>
  );
};

export default LandingFAQEntry;
