import React, { Component } from 'react';

import Helmet from './Helmet';

const Title = 'Canny: Customer Feedback Management Software and Tools';
const Description =
  'Canny helps you collect and organize feature requests to better understand customer needs and prioritize your roadmap.';
const Image = 'https://canny.io/images/canny-social.png';

export default class LandingHelmet extends Component {
  render() {
    return (
      <Helmet
        title={Title}
        meta={[
          { name: 'description', content: Description },

          { property: 'og:image', content: Image },
          { property: 'og:title', content: Title },
          { property: 'og:description', content: Description },
          { property: 'og:url', content: 'https://canny.io' },
          { property: 'og:type', content: 'website' },

          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@cannyHQ' },
          { name: 'twitter:title', content: Title },
          { name: 'twitter:description', content: Description },
          { name: 'twitter:image', content: Image },
          {
            name: 'zoom-domain-verification',
            content: 'ZOOM_verify_9d424f87baeb40b39d558d63ddb25072',
          },
        ]}
      />
    );
  }
}
