import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';

import 'css/components/www/LandingV3/_LandingTestimonials.scss';

import delayer from 'common/util/delayer';
import nbspLastSpace from 'common/util/nbspLastSpace';
import LandingTestimonialCard, {
  type Props as Testimonial,
} from 'common/www/LandingV3/LandingTestimonialCard';

type Copy = {
  title: string;
  subtitle?: string;
};

type CompanyTestimonials = Record<string, Testimonial[]>;

export type Props = {
  copy: Copy;
  categorizedTestimonials: CompanyTestimonials;
  hideHeading?: boolean;
  centerHeading?: boolean;
};

const LandingTestimonials = ({
  copy: { title, subtitle },
  categorizedTestimonials,
  hideHeading = false,
  centerHeading = false,
}: Props) => {
  const categories = Object.keys(categorizedTestimonials);
  const [chosenCategoryIndex, setChosenCategoryIndex] = useState(0);
  const categoryButtons = useRef<(null | HTMLButtonElement)[]>([]);
  const testimonialCards = useRef<(null | HTMLElement)[]>([]);
  const [maxCardHeight, setMaxCardHeight] = useState(320);

  useEffect(() => {
    calcMinCardHeight();
    const delayer = heightCalcDelayer.current;
    const resizeCheck = () => {
      delayer.callAfterDelay();
    };
    window.addEventListener('resize', resizeCheck);
    return () => {
      delayer.cancel();
      window.removeEventListener('resize', resizeCheck);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calcMinCardHeight = () => {
    let currentMaxHeight = maxCardHeight;
    testimonialCards.current.forEach((el) => {
      if (el) {
        const height = el.getBoundingClientRect().height;
        if (height > currentMaxHeight) {
          currentMaxHeight = height;
        }
      }
    });
    setMaxCardHeight(currentMaxHeight);
  };

  const heightCalcDelayer = useRef(new delayer(calcMinCardHeight, 50));

  const onKeyDown = (e: React.KeyboardEvent) => {
    const pressed = e.key;

    let change = 0;
    if (pressed === 'ArrowRight') {
      change += 1;
    } else if (pressed === 'ArrowLeft') {
      change -= 1;
    }

    let newIndex = chosenCategoryIndex + change;

    if (newIndex < 0) {
      newIndex = categories.length - 1;
    } else if (newIndex >= categories.length) {
      newIndex = 0;
    }

    categoryButtons.current[newIndex]?.focus();

    setChosenCategoryIndex(newIndex);
  };

  return (
    <section className="LandingTestimonials">
      {!hideHeading ? (
        <div
          className={classnames('LandingTestimonials__heading', {
            'LandingTestimonials__heading--centered': centerHeading,
          })}>
          <div>
            <h2 className="LandingTestimonials__title" id="testimonials-title">
              {nbspLastSpace(title)}
            </h2>
            {subtitle && <p className="LandingTestimonials__subtitle">{nbspLastSpace(subtitle)}</p>}
          </div>
          {categories.length > 1 && (
            <div
              className="LandingTestimonials__tab-list"
              role="tablist"
              aria-labelledby="testimonials-title">
              {categories.map((category, index) => {
                return (
                  <button
                    className={classnames('LandingTestimonials__tab-button', {
                      'LandingTestimonials__tab-button--outlined': chosenCategoryIndex !== index,
                    })}
                    tabIndex={chosenCategoryIndex === index ? 0 : -1}
                    id={`tab-${index + 1}`}
                    role="tab"
                    aria-selected="true"
                    aria-controls={`tabpanel-${index + 1}`}
                    key={index}
                    onClick={() => setChosenCategoryIndex(index)}
                    onKeyDown={onKeyDown}
                    ref={(e) => (categoryButtons.current[index] = e)}>
                    {category}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      ) : null}
      <div
        className="LandingTestimonials__outer-panel-wrapper"
        style={{ minHeight: maxCardHeight }}>
        {categories.map((category, index) => {
          return (
            <div
              key={index}
              id={`tabpanel-${index + 1}`}
              role="tabpanel"
              aria-labelledby={`tab-${index + 1}`}>
              <ul
                ref={(e) => (testimonialCards.current[index] = e)}
                className={classnames(
                  'LandingTestimonials__panel-container',
                  `LandingTestimonials__panel-container--${
                    index === chosenCategoryIndex ? 'visible' : 'hidden'
                  }`
                )}>
                {categorizedTestimonials[category].map((testimonial, index) => {
                  return (
                    <li className="LandingTestimonials__panel-item" key={index}>
                      <LandingTestimonialCard {...testimonial} />
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default LandingTestimonials;
