import React from 'react';

import { ArrowUpWideNarrow, BarChart2, Megaphone, MessagesSquare, Search } from 'lucide-react';

import LandingTabsAsLinks, {
  type Props as Tabs,
} from 'common/www/LandingV3/LandingTabs/LandingTabsAsLinks';

const FeatureTabs: Tabs = {
  copy: { title: '', subtitle: '' }, // placeholder for typings
  tabs: [
    {
      title: 'Feedback discovery',
      body: 'Automatically collect and centralize feedback from all sources',
      icon: MessagesSquare,
      url: '/features/autopilot',
    },
    {
      title: 'Analyze feedback',
      body: 'Review consolidated feedback to inform your product decisions',
      icon: Search,
      url: '/features/analyze-feedback',
    },
    {
      title: 'Prioritize requests',
      body: 'See which features are most requested and important',
      icon: ArrowUpWideNarrow,
      url: '/features/product-roadmap',
    },
    {
      title: 'Build roadmaps',
      body: "Create roadmaps to show customers you're working on their feedback",
      icon: BarChart2,
      iconClassModifier: 'rotate-90',
      url: '/use-cases/public-roadmap',
    },
    {
      title: 'Share updates',
      body: "Publish release notes to let customers know you've acted on their feedback",
      icon: Megaphone,
      url: '/features/product-changelog',
    },
  ],
};

const LandingFeatureTabs = ({
  title,
  subtitle,
  activeTabIndex,
}: {
  title: string;
  subtitle: string;
  activeTabIndex?: number;
}) => {
  return (
    <LandingTabsAsLinks
      {...FeatureTabs}
      copy={{ title, subtitle }}
      defaultActiveTab={activeTabIndex}
    />
  );
};

export default LandingFeatureTabs;
