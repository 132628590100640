import React from 'react';

import classnames from 'classnames';

import nbspLastSpace from 'common/util/nbspLastSpace';
import styles from 'css-module/components/www/_LandingHeading.module.scss';

type Props = {
  title: string;
  subtitle?: string;
  center?: boolean;
  className?: string;
};

const LandingHeading = ({ title, subtitle, center = false, className }: Props) => {
  return (
    <div
      className={classnames([className, styles.heading], {
        [styles.center]: center,
      })}>
      <h2 className={styles.title}>{nbspLastSpace(title)}</h2>
      {subtitle ? <p className={styles.subtitle}>{nbspLastSpace(subtitle)}</p> : null}
    </div>
  );
};

export default LandingHeading;
